function toggleSideMenu(){
  var offcanvas_id = "#main_menu";
  $(offcanvas_id).toggleClass("show");
  $('body').toggleClass("offcanvas-active");
  $(".screen-overlay").toggleClass("show");
}

function closeSideMenu(){
    $(".screen-overlay").removeClass("show");
    $(".mobile-offcanvas").removeClass("show");
    $("body").removeClass("offcanvas-active");

}

function sideMenuToggle(e) {
  //console.log(e);
$(e).parent().parent().find("a").toggleClass('active');
}
